<template>
    <b-col>
        <b-row v-for="dropdownIndex in 5" :key="dropdownIndex">
            <b-col
                v-if="isDropDownVisible(dropdownIndex) && dropdown['field'+dropdownIndex+'Values'].length > 0"
                :key="dropdownIndex"

                class="mt-3"
            >
                <label
                    :class="{'required-field': isDropDownRequired(dropdownIndex) }"
                    class="float-left player-info-label"
                >{{ profileDropdownFields['field'+dropdownIndex].label }}</label>
                <b-form-select
                    v-model="v$.localPlayer['field'+ dropdownIndex].$model"
                    class="custom-select dropdown custom-dropdown dropdown-toggle"
                    :class="{
                        'is-valid': !v$.localPlayer['field'+ dropdownIndex].$error && v$.localPlayer['field'+ dropdownIndex].$dirty && localPlayer['field'+ dropdownIndex],
                        'is-invalid': v$.localPlayer['field'+ dropdownIndex].$error
                    }"
                    @input="(event) => onDropDownChange(event, dropdownIndex)"
                >
                    <b-form-select-option
                        :key="0"
                        :value="null"
                        disabled
                        class="default-option"
                    >{{ profileDropdownFields['field'+dropdownIndex].instructions }}</b-form-select-option>
                    <b-form-select-option
                        v-for="value of dropdown['field'+dropdownIndex+'Values']"
                        :key="value.id"
                        :name="value.title"
                        :value="value"
                    >
                        {{ value.title }}
                    </b-form-select-option>
                </b-form-select>
                <div class="input-error-container">
                    <small
                        v-for="error of v$.localPlayer['field'+ dropdownIndex].$errors"
                        :key="error.$uid"
                        class="error"
                    >{{ error.$message }} </small>
                </div>
            </b-col>
        </b-row>
    </b-col>
</template>

<script>
    import playerProfileFieldsService from '@/services/player-profile-field-service'
    import useVuelidate from '@vuelidate/core'
    import { requiredIf, helpers } from '@vuelidate/validators'

    export default {
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        data() {
            return {
                localPlayer: {
                    field1: null,
                    field2: null,
                    field3: null,
                    field4: null,
                    field5: null
                },
                dropdown: {
                    field1Values: [],
                    field2Values: [],
                    field3Values: [],
                    field4Values: [],
                    field5Values: []
                }
            }
        },
        validations() {
            return {
                localPlayer: {
                    field1: {
                        requiredIf: helpers.withMessage(this.$t('generalErrors.required'), requiredIf(() => this.isDropDownRequired(1)))
                    },
                    field2: {
                        requiredIf: helpers.withMessage(this.$t('generalErrors.required'), requiredIf(() => this.isDropDownRequired(2)))
                    },
                    field3: {
                        requiredIf: helpers.withMessage(this.$t('generalErrors.required'), requiredIf(() => this.isDropDownRequired(3)))
                    },
                    field4: {
                        requiredIf: helpers.withMessage(this.$t('generalErrors.required'), requiredIf(() => this.isDropDownRequired(4)))
                    },
                    field5: {
                        requiredIf: helpers.withMessage(this.$t('generalErrors.required'), requiredIf(() => this.isDropDownRequired(5)))
                    }
                }
            }
        },
        computed: {
            player() {
                return this.$store.getters.getCurrentUser
            },
            profileDropdownFields() {
                return this.$store.getters.getPlayerProfileDropdownFields
            }
        },
        watch: {
            player() {
                this.populateLocalPlayer()
            }
        },
        created() {
            for (let i = 1; i <= 5; i++) {
                playerProfileFieldsService.getFieldValues(i).then((response) => {
                    this.dropdown['field' + i + 'Values'] = response.data
                })
            }
            this.populateLocalPlayer()
        },
        methods: {
            populateLocalPlayer() {
                for (let i = 1; i <= 5; i++) {
                    if (this.player['field' + i]) {
                        this.localPlayer['field' + i] = this.player['field' + i]
                    }
                }
            },
            isDropDownRequired(dropFieldIndex) {
                return this.profileDropdownFields['field' + dropFieldIndex]?.required
            },
            isDropDownVisible(dropFieldIndex) {
                return this.profileDropdownFields['field' + dropFieldIndex]?.visible
            },
            onDropDownChange(value, index) {
                this.localPlayer['field' + index] = value
                this.v$.localPlayer['field' + index].$touch()
                this.$emit('update-player-profile', this.localPlayer)
            }
        }
    }
</script>

<style scoped>
.required-field::after {
    content: "*";
}

.dropdown,
.dropdown option {
    color: var(--black);
    cursor: pointer;
}

.dropdown .default-option {
    font-weight: bold;
}

.custom-dropdown {
    width: 100%;
    border: 1px solid var(--black);
    border-radius: 5px;
    height: 40px;
    color: var(--black);
    font-size: 100%;
    padding-left: 8px;
    font-weight: 700;
    background-color: rgb(255 255 255 / 75%);
}

.player-info-label {
    font-size: 1.3vw;
    color: var(--main-content-text);
    font-weight: 700;
}

.input-error-container {
    width: 100%;
    position: absolute;
}

.error {
    color: var(--error);
    font-size: 0.8rem;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
    display: block;
}

@media screen and (max-width: 1000px) {
    .player-info-label {
        font-size: 1rem;
    }
}
</style>
<style lang="scss">
.dropdown.ul .dropdown-menu {
    max-height: 80vw !important;
    overflow: scroll !important;
}

.custom-dropdown {
    &.is-valid {
        border: 1px solid #28a745;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 1px #28a745;
    }

    &.is-invalid {
        border: 1px solid var(--error);
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 1px var(--error);
    }
}

</style>
