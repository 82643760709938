var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"profile-route",attrs:{"fluid":""}},[_c('profile-header',{attrs:{"view":_vm.view}}),(_vm.view ==='edit')?_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-row',{staticClass:"justify-content-md-center pl-6"},[_c('b-col',{attrs:{"lg":"12"}},[_c('span',{staticClass:"player-info-label"},[_vm._v(_vm._s(_vm.$t('editProfile.selectAvatar')))])])],1),_c('div',{staticClass:"avatar-container"},[_c('avatar',{attrs:{"avatar":_vm.selectedAvatar? _vm.selectedAvatar : null}})],1),(_vm.isImageUploadEnabled)?_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"lg":"12"}},[_c('span',{staticClass:"btn upload-button mr-2",attrs:{"role":"button"},on:{"click":function($event){return _vm.viewTypeChanged('upload')}}},[_vm._v(_vm._s(_vm.$t('editProfile.uploadPhoto'))+" ")]),_c('span',{staticClass:"color-help"},[_vm._v(_vm._s(_vm.$t('editProfile.uploadText')))])])],1):_vm._e(),_c('b-row',[_c('avatar-selection',{attrs:{"avatars":_vm.avatars,"selected-avatar":_vm.selectedAvatar},on:{"avatar-selected":_vm.selectAvatar}})],1)],1),_c('b-col',{staticClass:"profile-divider",attrs:{"lg":"7"}},[_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"lg":"12"}},[_c('div',{staticClass:"color-help"},[_vm._v("*"+_vm._s(_vm.$t('editProfile.requiredFields')))])])],1),_c('div',{staticClass:"plainfields-container"},[(_vm.isPlainVisible('nickName'))?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"float-left player-info-label"},[_vm._v(" "+_vm._s(_vm.$t('editProfile.nickName'))),(_vm.isPlainRequired('nickName'))?_c('span',[_vm._v("*")]):_vm._e()]),_c('b-form-input',{staticClass:"player-info-input",class:{
                                'is-valid': !_vm.v$.player.nickName.$error && _vm.v$.player.nickName.$dirty && _vm.player.nickName,
                                'is-invalid': _vm.v$.player.nickName.$error
                            },attrs:{"formatter":function (event) { return _vm.formatInput(event,26); },"trim":"","type":"text"},on:{"blur":_vm.v$.player.nickName.$touch},model:{value:(_vm.v$.player.nickName.$model),callback:function ($$v) {_vm.$set(_vm.v$.player.nickName, "$model", $$v)},expression:"v$.player.nickName.$model"}}),_c('div',{staticClass:"input-error-container"},_vm._l((_vm.v$.player.nickName.$errors),function(error){return _c('small',{key:error.$uid,staticClass:"error"},[_vm._v(_vm._s(error.$message)+" ")])}),0)],1):_vm._e(),(_vm.isPlainVisible('firstName'))?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"float-left player-info-label",class:{'required-field': _vm.isPlainRequired('firstName') }},[_vm._v(" "+_vm._s(_vm.$t('editProfile.name'))+" ")]),_c('b-form-input',{staticClass:"player-info-input",class:{
                                'is-valid': !_vm.v$.player.firstName.$error && _vm.v$.player.firstName.$dirty && _vm.player.firstName,
                                'is-invalid': _vm.v$.player.firstName.$error
                            },attrs:{"name":"name","trim":"","type":"text"},on:{"blur":_vm.v$.player.firstName.$touch},model:{value:(_vm.v$.player.firstName.$model),callback:function ($$v) {_vm.$set(_vm.v$.player.firstName, "$model", $$v)},expression:"v$.player.firstName.$model"}}),_c('div',{staticClass:"input-error-container"},_vm._l((_vm.v$.player.firstName.$errors),function(error){return _c('small',{key:error.$uid,staticClass:"error"},[_vm._v(_vm._s(error.$message)+" ")])}),0)],1):_vm._e(),(_vm.isPlainVisible('lastName'))?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"float-left player-info-label",class:{'required-field': _vm.isPlainRequired('lastName') }},[_vm._v(" "+_vm._s(_vm.$t('editProfile.surname'))+" ")]),_c('b-form-input',{staticClass:"player-info-input",class:{
                                'is-valid': !_vm.v$.player.lastName.$error && _vm.v$.player.lastName.$dirty && _vm.player.lastName,
                                'is-invalid': _vm.v$.player.lastName.$error
                            },attrs:{"name":"surname","trim":"","type":"text"},on:{"blur":_vm.v$.player.lastName.$touch},model:{value:(_vm.v$.player.lastName.$model),callback:function ($$v) {_vm.$set(_vm.v$.player.lastName, "$model", $$v)},expression:"v$.player.lastName.$model"}}),_c('div',{staticClass:"input-error-container"},_vm._l((_vm.v$.player.lastName.$errors),function(error){return _c('small',{key:error.$uid,staticClass:"error"},[_vm._v(_vm._s(error.$message)+" ")])}),0)],1):_vm._e(),(_vm.isPlainVisible('birthDate'))?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"float-left player-info-label",class:{'required-field': _vm.isPlainRequired('birthDate') }},[_vm._v(" "+_vm._s(_vm.$t('editProfile.birthDate'))+" ")]),_c('date-picker',{attrs:{"disabled-dates":_vm.disabledDates,"input-class":{
                                'player-info-input': true,
                                'is-valid': !_vm.v$.player.birthDate.$error && _vm.v$.player.birthDate.$dirty && _vm.player.birthDate,
                                'is-invalid': _vm.v$.player.birthDate.$error
                            },"language":_vm.datePickerLanguage,"value":_vm.playerBirthDate,"clear-button":"","initial-view":"year","maximum-view":"year","minimum-view":"day"},on:{"cleared":_vm.v$.player.birthDate.$touch,"selected":_vm.selectBirthDate}}),_c('div',{staticClass:"input-error-container"},_vm._l((_vm.v$.player.birthDate.$errors),function(error){return _c('small',{key:error.$uid,staticClass:"error"},[_vm._v(_vm._s(error.$message)+" ")])}),0)],1):_vm._e(),_c('div',{staticClass:"mobile-col"},[(_vm.isPlainVisible('mobilePhone'))?_c('profile-mobile-input',{attrs:{"incoming-mobile":_vm.player.mobile,"is-valid-response":_vm.isPhoneNumberValid,"registration-channel":_vm.registrationChannel},on:{"phone-updated":_vm.updatePhone}}):_vm._e(),_c('div',{staticClass:"input-error-container"},_vm._l((_vm.v$.formattedMobile.$errors),function(error){return _c('small',{key:error.$uid,staticClass:"error"},[_vm._v(_vm._s(error.$message)+" ")])}),0)],1),(_vm.isPlainVisible('email'))?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"float-left player-info-label",class:{'required-field': _vm.isPlainRequired('email') }},[_vm._v(" "+_vm._s(_vm.$t('editProfile.email'))+" ")]),_c('b-form-input',{staticClass:"player-info-input",class:{
                                'is-valid': !_vm.v$.playerEmail.$error && _vm.v$.playerEmail.$dirty && _vm.playerEmail,
                                'is-invalid': _vm.v$.playerEmail.$error
                            },attrs:{"name":"email","trim":"","type":"email"},on:{"blur":_vm.v$.playerEmail.$touch,"input":_vm.setPlayerEmail},model:{value:(_vm.v$.playerEmail.$model),callback:function ($$v) {_vm.$set(_vm.v$.playerEmail, "$model", $$v)},expression:"v$.playerEmail.$model"}}),_c('div',{staticClass:"input-error-container"},_vm._l((_vm.v$.playerEmail.$errors),function(error){return _c('small',{key:error.$uid,staticClass:"error"},[_vm._v(_vm._s(error.$message))])}),0)],1):_vm._e(),(_vm.isPlainVisible('loyalty'))?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"float-left player-info-label"},[_vm._v(_vm._s(_vm.profilePlainFields.loyalty.label)),(_vm.isPlainRequired('loyalty'))?_c('span',[_vm._v("*")]):_vm._e()]),_c('b-form-input',{staticClass:"player-info-input",class:{
                                'is-valid': !_vm.v$.player.loyaltyId.$error && _vm.v$.player.loyaltyId.$dirty && _vm.player.loyaltyId,
                                'is-invalid': _vm.v$.player.loyaltyId.$error
                            },attrs:{"type":"text"},on:{"blur":_vm.v$.player.loyaltyId.$touch},model:{value:(_vm.v$.player.loyaltyId.$model),callback:function ($$v) {_vm.$set(_vm.v$.player.loyaltyId, "$model", $$v)},expression:"v$.player.loyaltyId.$model"}}),_c('div',{staticClass:"input-error-container"},_vm._l((_vm.v$.player.loyaltyId.$errors),function(error){return _c('small',{key:error.$uid,staticClass:"error"},[_vm._v(_vm._s(error.$message)+" ")])}),0)],1):_vm._e()]),_c('b-row',[_c('profile-dropdown-fields',{on:{"update-player-profile":_vm.playerDropdownChoicesUpdated}})],1),(_vm.isPlainVisible('fileUpload'))?_c('b-row',{staticClass:"my-4"},[_c('b-col',[_c('label',{staticClass:"float-left player-info-label",class:{'required-field': _vm.isPlainRequired('fileUpload') }},[_vm._v(" "+_vm._s(_vm.$t('editProfile.uploadFileLabel'))+" ")]),_c('file-upload',{attrs:{"has-error":_vm.v$.player.uploadedFileUrl.$error,"max-size":15,"uploaded-file":_vm.player.uploadedFileUrl},on:{"file-updated":_vm.updateFile}}),_c('div',{staticClass:"input-error-container"},_vm._l((_vm.v$.player.uploadedFileUrl.$errors),function(error){return _c('small',{key:error.$uid,staticClass:"error"},[_vm._v(_vm._s(error.$message)+" ")])}),0)],1)],1):_vm._e(),_c('b-row',{staticClass:"form-error-container"},[_c('b-col',[_c('div',[(_vm.errorMessageToShow)?_c('strong',{staticClass:"text-danger form-error"},[_vm._v(" "+_vm._s(_vm.errorMessageToShow)+" ")]):_vm._e()])])],1),_c('div',{staticClass:"save-button",attrs:{"role":"button"},on:{"click":_vm.saveProfile}},[_vm._v(" "+_vm._s(_vm.$t('editProfile.save'))+" ")])],1)],1)],1):_vm._e(),(_vm.view ==='upload')?_c('b-container',{attrs:{"fluid":""}},[_c('upload-photo',{on:{"back":_vm.viewTypeChanged,"image":_vm.setUploadedPhoto}})],1):_vm._e(),(_vm.view ==='crop')?_c('b-container',{attrs:{"fluid":""}},[_c('crop-photo',{attrs:{"uploaded-photo":_vm.uploadedPhoto},on:{"newPhoto":_vm.viewTypeChanged}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }