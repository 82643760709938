<template>
    <div>
        <b-form-file
            ref="file-input"
            v-model="file"
            class="file-input"
            :placeholder="$t('fileUpload.uploadLabel')"
            :drop-placeholder="$t('fileUpload.uploadLabel')"
            :accept="accept"
            :state="uploadInputState()"
            @input="fileLoaded"
        />
        <b-progress
            v-show="uploadPercentage > 0"
            :max="100"
            show-progress
            height="13px"
            class="mb-1"
        >
            <b-progress-bar
                :value="uploadPercentage"
                variant="info"
            >
                {{ uploadPercentage }}%
            </b-progress-bar>
        </b-progress>
        <div class="warnings">
            <small v-if="uploadedFile" class="preview-row">
                <div>{{ $t('fileUpload.click') }} <span
                    class="download-link"
                    @click="downloadFile"
                >{{ $t('fileUpload.here') }}</span> {{ $t('fileUpload.toPreview') }}</div>
                <span
                    class="clear-file"
                    @click="clearFile"
                >{{ $t('fileUpload.delete') }}</span>
            </small>
            <small
                v-show="showFileSizeWarning"
                class="form-text text-danger text-left"
            >{{ $t('fileUpload.fileTooBig') }}</small>
        </div>
    </div>
</template>

<script>
    import assetService from '@/services/asset-service'
    export default {

        props: {
            uploadPercentage: {
                type: Number,
                required: false,
                default: 0
            },
            required: {
                type: Boolean,
                required: false,
                default: false
            },
            hasError: {
                type: Boolean,
                required: false,
                default: false
            },
            uploadedFile: {
                type: String,
                required: false,
                default: null
            },
            accept: {
                type: String,
                required: false,
                default: '*'
            },
            maxSize: {
                type: Number,
                required: false,
                default: 3
            }
        },
        data() {
            return {
                file: null,
                showFileSizeWarning: false,
                assetRelativePath: null
            }
        },
        computed: {
            currentPlayer() {
                return this.$store.getters.getCurrentUser
            }
        },
        methods: {
            downloadFile() {
                assetService.getPrivateAssetPresignedUrl(this.uploadedFile)
                    .then((url) => {
                        this.assetRelativePath = url.data
                    })
                    .then(() => {
                        window.open(`${process.env.VUE_APP_API_HOST_DOMAIN}/private-assets/${this.assetRelativePath}`, '_blank')
                    })
            },
            clearFile() {
                if (this.file) {
                    this.file = null
                }
                this.$emit('file-updated', null)
            },
            uploadInputState() {
                if (this.hasError) {
                    return false
                }
                if (!this.file && this.required) {
                    return false
                }
                if (this.showFileSizeWarning) {
                    return false
                }
                return null
            },
            fileLoaded(file) {
                this.showFileSizeWarning = this.checkFileSize(file)

                if (this.showFileSizeWarning === false) {
                    this.$emit('file-updated', file)
                } else {
                    this.$emit('file-updated', null)
                }
            },
            checkFileSize(uploadedImage) {
                if (!uploadedImage) {
                    return false
                }
                const fileSize = uploadedImage.size / 1024 / 1024
                return fileSize > this.maxSize
            }
        }
    }
</script>
<style>
.custom-file-label {
    cursor: pointer;
}
</style>

<style scoped>
.cv-input {
    display: none;
}

.warnings {
    display: flex;
    flex-direction: column;
}

.preview-row {
    margin-top: 0.1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--main-content-text);
}

.clear-file {
    cursor: pointer;
    background: var(--form-error-color);
    color: #fff;
    padding: 0 0.5rem;
    border-radius: 0.25rem;
}

.download-link {
    cursor: pointer;
    color: #0000cd;
}

</style>
