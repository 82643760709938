<template>
    <div
        v-if="view ==='edit'"
        class="profile-header-container"
    >
        <span class="text-profile-title-first">{{ $t('editProfile.headerFirstText') }}</span>
        <badges-component
            v-if="isBadgesEnabled"
            class="mobile-only mobile-badges-container"
        />
        <span class="text-profile-title-second header-top">
            {{ $t('editProfile.headerSecondText') }}
        </span>
    </div>
    <div v-else-if="view ==='upload'" class="profile-header-container header-bottom">
        <badges-component
            v-if="isBadgesEnabled"
            class="mobile-only mobile-badges-container"
        />
        <span class="text-profile-title-first">{{ $t('editProfile.uploadPhoto') }}</span>
    </div>
    <div v-else-if="view === 'crop'" class="profile-header-container header-bottom">
        <badges-component
            v-if="isBadgesEnabled"
            class="mobile-only mobile-badges-container"
        />
        <span class="text-profile-title-first">{{ $t('editProfile.cropPhoto') }}</span>
    </div>
</template>

<script>
    import BadgesComponent from '@/components/BadgesComponent'
    export default {
        components: {
            BadgesComponent
        },
        props: {
            view: {
                type: String,
                required: true
            }
        },
        computed: {
            isBadgesEnabled() {
                return this.$store.getters.isBadgesEnabled
            }
        }
    }
</script>

<style scoped>
.profile-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 450px;
    margin: 0 auto;
}

.header-top {
    text-align: center;
    color: var(--main-content-text);
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    max-width: 90%;
    border-top: 2px solid var(--main-content-text);
}

.header-bottom {
    text-align: left;
    color: var(--main-content-text);
    border-bottom: 2px solid var(--main-content-text);
}

.text-profile-title-first {
    font-weight: 700;
    font-size: 28px;
    color: var(--main-content-text);
}

.text-profile-title-second {
    font-weight: 300;
    font-size: 16px;
    color: var(--main-content-text);
    width: 100%;
}

.mobile-badges-container {
    max-width: 400px;
    margin: 0 auto;
}

@media screen and (min-width: 992px) {
    .profile-header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        max-width: 95%;
        margin: 0 0 0 1rem;
        padding-bottom: 0.5rem;
        border-bottom: 2px solid var(--main-content-text);
    }

    .header-top {
        text-align: center;
        color: var(--main-content-text);
        padding-top: 0;
        margin-top: 0;
        max-width: 90%;
        border-top: none;
    }

    .text-profile-title-second {
        font-weight: 300;
        font-size: 16px;
        color: var(--main-content-text);
        width: unset;
    }
}
</style>
