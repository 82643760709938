<template>
    <div class="avatars-section">
        <div
            v-for="avatar of avatars"
            :key="avatar.id"
            :class="{'selected': selectedAvatar && avatar.id === selectedAvatar.id }"
            @click="selectAvatar(avatar)"
        >
            <b-img
                class="avatar-select"
                :src="`${avatar.imageUrl}`"
            />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            avatars: {
                type: Array,
                required: true
            },
            selectedAvatar: {
                type: Object,
                required: false,
                default: null
            }
        },
        methods: {
            selectAvatar(avatar) {
                this.$emit('avatar-selected', avatar)
            }
        }
    }
</script>

<style scoped>
.avatars-section {
    margin: 2rem auto;
    overflow: auto;
    max-width: 300px;
    max-height: 390px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.avatar-select {
    width: 70px;
    height: 70px;
    border: solid var(--white);
    border-radius: 50%;
    margin-bottom: 10px;
}

.avatar-select:hover,
.selected .avatar-select {
    border: solid var(--secondary-color);
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .avatars-section {
        max-height: 100px;
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        justify-content: flex-start;
        margin: 2rem 0;
        overflow: auto;
        gap: 5px;
    }
}

@media screen and (max-width: 1000px) {
    .avatars-section {
        max-width: 400px;
        max-height: 100px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        margin: 2rem auto;
        overflow: auto;
        gap: 5px;
    }
}
</style>
