<template>
    <div
        v-if="getPreferredLanguages"
        class="form-group"
    >
        <label
            :class="{'required-field': isPlainRequired() }"
            class="float-left player-info-label"
        >
            {{ $t('editProfile.phone') }}
        </label>
        <vue-phone-number-input
            v-if="getPreferredLanguages"
            v-model="playerMobile.phoneNumber"
            class="player-mobile-input"
            :default-country-code="defaultCountryCode"
            :preferred-countries="getPreferredLanguages"
            name="tel"
            :dark="false"
            :dark-color="'#356c9a'"
            :translations="phoneNumberLocale"
            :border-radius="5"
            :error="!isValidResponse"
            @update="phoneUpdated"
        />
    </div>
</template>

<script>
    import VuePhoneNumberInput from 'vue-phone-number-input'
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'
    import phoneUtil from 'phone'
    export default {
        components: {
            VuePhoneNumberInput
        },
        props: {
            incomingMobile: {
                type: String,
                default: ''
            },
            registrationChannel: {
                type: String,
                default: ''
            },
            isValidResponse: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                isPhoneNumberValid: true,
                playerMobile: {
                    countryCode: null,
                    phoneNumber: '',
                    formattedNumber: ''
                },
                phoneNumberLocale: {
                    countrySelectorLabel: this.$t('editProfile.countryCode'),
                    phoneNumberLabel: this.$t('editProfile.phoneNumber')
                }
            }
        },
        computed: {
            player() {
                return this.$store.getters.getCurrentUser
            },
            profilePlainFields() {
                return this.$store.getters.getPlayerProfileFields
            },
            getPreferredLanguages() {
                return this.$store.getters.getPreferredLanguages || []
            },
            defaultCountryCode() {
                if (this.playerMobile?.countryCode) {
                    return this.playerMobile.countryCode
                }
                return this.getPreferredLanguages?.length > 0 ? this.getPreferredLanguages[0] : ''
            }
        },
        watch: {
            incomingMobile: {
                handler(newValue) {
                    this.prepareMobile(newValue)
                },
                immediate: true
            }

        },
        methods: {
            prepareMobile(incomingMobile) {
                if (incomingMobile) {
                    let mobile = phoneUtil(`+${incomingMobile}`)
                    if (mobile.isValid) {
                        this.playerMobile.countryCode = mobile.countryIso2
                        this.playerMobile.phoneNumber = mobile.phoneNumber.substring(mobile.countryCode.length)
                        this.playerMobile.formattedNumber = mobile.phoneNumber.substring(1)
                        this.isPhoneNumberValid = true

                        this.updatePhone()
                    }
                }
            },
            isPlainRequired() {
                return this.profilePlainFields.mobilePhone?.required || this.registrationChannel === 'SMS'
            },
            phoneUpdated(payload) {
                if (!payload.hasOwnProperty('phoneNumber')) {
                    this.playerMobile.formattedNumber = null
                }
                if (payload?.formattedNumber) {
                    this.playerMobile.formattedNumber = payload.formattedNumber.substring(1)
                }
                if (payload.hasOwnProperty('isValid')) {
                    this.isPhoneNumberValid = payload.isValid
                }
                this.updatePhone()
            },
            updatePhone() {
                this.$emit('phone-updated', { playerMobile: this.playerMobile, isPhoneNumberValid: this.isPhoneNumberValid })
            }
        }
    }
</script>

<style scoped>
.required-field::after {
    content: "*";
}

.form-input {
    width: 100%;
    background-color: rgb(255 255 255 / 75%);
    border: 1px solid var(--black);
    border-radius: 5px;
    height: 40px;
    color: var(--black);
    font-size: 100%;
    padding-left: 8px;
    font-weight: 700;
}

.form-group {
    width: calc((100% - 1rem) / 2);
    margin-bottom: 0;
}

.player-info-label {
    color: var(--main-content-text);
    font-weight: 700;
    font-size: 1rem;
}

@media (min-width: 999px) {
    .player-info-label {
        color: var(--main-content-text);
        font-weight: 700;
        font-size: 1.3vw;
    }
}

</style>

<style>
.player-mobile-input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
}

.country-selector__input,
.input-tel__input {
    font-weight: bold !important;
    font-size: 1rem !important;
    background-color: rgb(255 255 255 / 75%) !important;
}
</style>
